import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from '@environment/environment'

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  private s3: AWS.S3;
  imageUrl: string;
  env
  constructor() {
    this.env = environment
  }

  /* getS3Object(bucketName: string, key: string): Promise<string> {
  const extension = key.substring(key.lastIndexOf('.') + 1);
    const mimeType = this.getMimeType(extension);
    console.log('mimetype', mimeType);
    const params = {
      Bucket: bucketName,
      Key: key,
    };
    return new Promise((resolve, reject) => {
      this.s3.getObject(params, (err, data) => {
        if (err) {
          console.error('Error fetching image data:', err);
          return reject(err);
        }
        let arrayBufferPromise: Promise<ArrayBuffer>;
        // Handle different data.Body formats
        if (data.Body instanceof ArrayBuffer) {
          arrayBufferPromise = Promise.resolve(data.Body);
        } else if (data.Body instanceof Uint8Array) {
          arrayBufferPromise = Promise.resolve(data.Body.buffer);
        } else if (data.Body instanceof Blob) {
          arrayBufferPromise = data.Body.arrayBuffer();
        } else {
          return reject(new Error('Unexpected data format'));
        }
        // Convert ArrayBuffer to Blob and create URL
        arrayBufferPromise
          .then((arrayBuffer) => {
            const imageBlob = new Blob([arrayBuffer], { type: mimeType }); // Adjust MIME type if needed
            const imageUrl = URL.createObjectURL(imageBlob);
            resolve(imageUrl);
          })
          .catch(reject);
      });
    });
  } */
  getS3Object(unknown: string, key: string): Promise<string> {
    const fileUrl = key.replace(this.env.attachementUrl, '');
    return Promise.resolve(this.env.attachementUrl + fileUrl);
  }
  createImageFromBlob(blob) {
    if (!blob) return;
    const reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result as string;
    };
    reader.readAsDataURL(blob); // Convert Blob to base64 URL
  }
  getMimeType(extension) {
    const mimeTypes = {
      'pdf': 'application/pdf',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'txt': 'text/plain',
      'html': 'text/html',
      'css': 'text/css',
      'js': 'application/javascript',
      'json': 'application/json',
      'xml': 'application/xml',
      'csv': 'text/csv',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'xls': 'application/vnd.ms-excel',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc': 'application/msword',
      'mp3': 'audio/mpeg',
      'mp4': 'video/mp4',
      'avi': 'video/x-msvideo',
      'zip': 'application/zip',
      // Add more types as needed
    };
    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
  }
}
